import validate from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/pages/runtime/validate.js";
import stripe_45redirect_45global from "/usr/prnews/middleware/stripe-redirect.global.ts";
import manifest_45route_45rule from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  stripe_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/prnews/middleware/auth.ts"),
  "not-auth": () => import("/usr/prnews/middleware/notAuth.ts"),
  publisher: () => import("/usr/prnews/middleware/publisher.ts"),
  "publisher-flow": () => import("/usr/prnews/middleware/publisherFlow.ts"),
  "uncompleted-survey": () => import("/usr/prnews/middleware/uncompletedSurvey.ts")
}