import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { QueryOptions } from '~/src/types/QueryOptions'
import type { paths } from '~/src/api/schema'
import type { MediaProject } from '~/src/types/MediaProject'
import { GET_CLIENT_USER } from '~/composables/queries/useClientUser'

const POST_MEDIA_PROJECT = '/api/media_projects'
export const GET_MEDIA_PROJECT = '/api/media_projects/{id}'
const GET_MEDIA_PROJECT_BY_URL = '/api/media_projects-find-by-url'
const PATCH_MEDIA_PROJECT = '/api/media_projects/{id}'
const SET_CURRENT_MEDIA_PROJECT= '/api/client_users/current-media'

export function postMediaProject() {
  return useMutation({
    mutationKey: [
      POST_MEDIA_PROJECT,
    ],
    mutationFn: async ({ body }: { body: QueryOptions<paths[typeof POST_MEDIA_PROJECT]['post']>['body'] }) => {
      const client = useApi()
      const { data } = await client.POST(POST_MEDIA_PROJECT, {
        body,
      })
      return data
    },
  })
}

export function getMediaProject({ params, query }: QueryOptions<paths[typeof GET_MEDIA_PROJECT]['get']>) {
  const id = ref<string>()
  watchEffect(() => {
    id.value = toValue(params)!.path!.id
  })
  return useQuery({
    ...query,
    queryKey: [
      GET_MEDIA_PROJECT,
      id,
    ],
    queryFn: async ({ signal }) => {
      const client = useApi()
      const { data } = await client.GET(GET_MEDIA_PROJECT, {
        params,
        signal,
      })
      return data as MediaProject
    },
  })
}

export function setCurrentMediaProject() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      SET_CURRENT_MEDIA_PROJECT,
    ],
    mutationFn: async ({ body }: { body: QueryOptions<paths[typeof SET_CURRENT_MEDIA_PROJECT]['patch']>['body'] }) => {
      const client = useApi()
      const { data } = await client.PATCH(SET_CURRENT_MEDIA_PROJECT, {
        body,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      await queryClient.invalidateQueries({ queryKey: [GET_CLIENT_USER] })
      return data
    },
  })
}

export function patchMediaProject() {
  return useMutation({
    mutationKey: [
      PATCH_MEDIA_PROJECT,
    ],
    mutationFn: async ({ params, body }: { params: QueryOptions<paths[typeof PATCH_MEDIA_PROJECT]['patch']>['params'], body: QueryOptions<paths[typeof PATCH_MEDIA_PROJECT]['patch']>['body'] }) => {
      const client = useApi()
      const { data } = await client.PATCH(PATCH_MEDIA_PROJECT, {
        params,
        body,
        headers: { 'Content-Type': 'application/merge-patch+json' },
      })
      return data
    },
  })
}

export function getMediaProjectByUrl({ params, query }: QueryOptions<paths[typeof GET_MEDIA_PROJECT_BY_URL]['get']>) {
  const search = ref<string>()
  const isEnabled = computed(() => !!search.value)
  watchEffect(() => {
    search.value = toValue(params)!.query!.search
  })
  return useQuery({
    ...query,
    queryKey: [
      GET_MEDIA_PROJECT_BY_URL,
      search
    ],
    queryFn: async ({ signal }) => {
      const client = useApi()
      const { data } = await client.GET(GET_MEDIA_PROJECT_BY_URL, {
        params,
        signal,
      })
      return data as MediaProject
    },
    enabled: isEnabled,
    retry: false
  })
}
