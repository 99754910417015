<script setup lang="ts">
import NotFoundImage from '~/assets/images/not-found.svg'
import Button from '~/components/molecules/Button.vue'

const handleError = () => clearError({ redirect: '/dashboard' })
</script>

<template>
  <div class="m-auto max-w-80 text-center">
    <div class="flex justify-center items-center h-[13.5rem] mb-2">
      <NotFoundImage />
    </div>
    <p class="text-body-regular-16 text-black-75 mb-8">
      Sorry, the page you are looking for doesn't exist or has been moved
    </p>
    <Button
      class="w-full justify-center"
      @click="handleError"
    >
      Take Me Home
    </Button>
  </div>
</template>

<style scoped lang="postcss">
</style>
