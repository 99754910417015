import { getClientUser } from '~/composables/queries/useClientUser'

export default defineNuxtRouteMiddleware(async () => {
  const supabaseUser = useSupabaseUser()
  const { data: user, suspense } = getClientUser({
    params: {
      path: { id: supabaseUser.value?.id || '' },
    },
    query: {
      enabled: supabaseUser.value?.id !== undefined,
      staleTime: Infinity,
    },
  })

  if (supabaseUser.value) {
    await suspense()
  }

  const localePath = useLocalePath()

  if (!user.value || !user.value.userMediaProjects.length) {
    return navigateTo(localePath('publisher'))
  }
})
