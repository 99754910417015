import type { AuthResponse } from '@supabase/auth-js'

declare module '#app' {
    interface NuxtApp {
        $refreshSession: () => Promise<AuthResponse | undefined>
    }
}

export default defineNuxtPlugin(async () => {
  const client = useSupabaseClient()
  const accessToken = useCookie('sb-access-token')
  const refreshToken = useCookie('sb-refresh-token')

  client.auth.onAuthStateChange((event, session) => {
    if (event !== 'TOKEN_REFRESHED') { return }
    accessToken.value = session?.access_token
  })

  const refreshSession = async () => {
    try {
      if (!refreshToken.value) { return }
      return client.auth.refreshSession({ refresh_token: refreshToken.value })
    } catch(e) {
      console.error(e)
    }
  }

  const { data } = await client.auth.getUser()
  if (!data.user) {
    await refreshSession()
  }

  return {
    provide: {
      refreshSession
    }
  }
})
