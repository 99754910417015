import revive_payload_client_vJSFkEp910 from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Kem7Y7TYu4 from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7QDaCpLk1c from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qkuhH53UVg from "/usr/prnews/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.31.0_vite@5.4.12_@types+node@18.17.5_terser@5_eq5pl7b3krgk5cqsvniof5osha/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import posthog_client_La5HPjgmMM from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import supabase_client_gkTEE3V33K from "/usr/prnews/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_7YWtWg7oeH from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2wy2jhhW3s from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YF6PPYSWDi from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_oWpVzFnlRg from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GwSqDQHDAN from "/usr/prnews/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.31.0_typescript@5.6.2_vue@3.5.13_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/prnews/.nuxt/components.plugin.mjs";
import prefetch_client_C14F1mPjh6 from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@18.17.5_db0@0.2.1_eslint@9.10.0_jiti@2.4.2__ior_xzuc6tmsuw4kkzybp5hxbgfkqa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_SHvy3nYusB from "/usr/prnews/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.6.0_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import sentry_client_O7SEBePRbX from "/usr/prnews/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+api@1._n4r3mlzg7ckgslw62bqfzwbt2q/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/prnews/.nuxt/sentry-client-config.mjs";
import directives_CMFtiAZjiQ from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import switch_locale_path_ssr_wBiKyRp6rz from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_DsKcj0eT34 from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_auth_66fnPBoWcQ from "/usr/prnews/plugins/01.auth.ts";
import segment_client_PKspGsfJUR from "/usr/prnews/plugins/segment.client.ts";
import validate_fk94mvjWUp from "/usr/prnews/plugins/validate.ts";
import vueQuery_HbXfDZmQp2 from "/usr/prnews/plugins/vueQuery.ts";
export default [
  revive_payload_client_vJSFkEp910,
  unhead_Kem7Y7TYu4,
  router_7QDaCpLk1c,
  _0_siteConfig_qkuhH53UVg,
  posthog_client_La5HPjgmMM,
  supabase_client_gkTEE3V33K,
  payload_client_7YWtWg7oeH,
  navigation_repaint_client_2wy2jhhW3s,
  check_outdated_build_client_YF6PPYSWDi,
  chunk_reload_client_oWpVzFnlRg,
  plugin_vue3_GwSqDQHDAN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_C14F1mPjh6,
  plugin_SHvy3nYusB,
  sentry_client_O7SEBePRbX,
  sentry_client_config_o34nk2sJbg,
  directives_CMFtiAZjiQ,
  switch_locale_path_ssr_wBiKyRp6rz,
  i18n_DsKcj0eT34,
  _01_auth_66fnPBoWcQ,
  segment_client_PKspGsfJUR,
  validate_fk94mvjWUp,
  vueQuery_HbXfDZmQp2
]