export function useSignOut() {
  const supabase = useSupabaseClient()

  const localePath = useLocalePath()
  const { analytics } = useAnalytics()

  const { $clientPosthog, $clearQueryClient } = useNuxtApp()

  const signOut = async () => {
    try {
      $clearQueryClient()
      await supabase.auth.signOut()
      $clientPosthog?.reset()
      analytics?.reset()
      localStorage?.clear()
      navigateTo(localePath('sign-in'))
    } catch(e) {
      console.error(e, 'error')
    }
  }

  return { signOut }
}
